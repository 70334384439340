@use "sass:color";

///////////////
// OVERRIDES //
///////////////

// IMAGE ROOT
$baseSitePath: "" !default;
$imageRoot: $baseSitePath + '/images/overrides/drench/';
$spriteVersion: "3";

// GLOBAL

$colorBase: #475257;

$colorWhite: #fff;
$colorOffWhite: #F2F2F0;

$colorAlpha: #0D7580;
$colorBeta: #0D7580;
$colorGamma: #0D7580;
$colorDelta: #0D7580;

$colorSale: #a43b51;

$colorEmphasise: $colorSale;

$colorLink: $colorBase;
$colorLinkHover: #9B9B9B;

$fontBase: 'BrandonTextWeb-Regular', 'BrandonTextWeb-Regular Fallback', 'BrandonTextWeb-Regular Fallback Android', Arial, Helvetica, Verdana, sans-serif;
$fontSize: 16px;
$fontHeight: 19px;

// RESET

$resetHeadingFontFamily: 'BrandonTextWeb-Bold', 'BrandonTextWeb-Bold Fallback', 'BrandonTextWeb-Bold Fallback Android', Arial, Verdana, Helvetica, sans-serif;
$resetHeadingFontWeight: normal;
$resetHeading1FontWeight: normal;
$resetHeading2FontWeight: normal;
$resetHeading3FontWeight: normal;
$resetHeading4FontWeight: normal;
$resetHeading5FontWeight: normal;
$resetHeading6FontWeight: normal;
$resetStrongFontWeight: normal;
$resetStrongFontFamily: 'BrandonTextWeb-Medium', 'BrandonTextWeb-Medium Fallback', 'BrandonTextWeb-Medium Fallback Android', Arial, Verdana, Helvetica, sans-serif;
$resetBoldestFontWeight: normal;
$resetBoldestFontFamily: $resetHeadingFontFamily;
$resetTableThTdColor: $colorBase;
$resetCMSLinkTextDecoration: underline;
$resetLinkFocusOutlineColor: #A6E8DB;

// FORMS
// _forms.scss

$formButtonBackgroundColor: $colorBeta;
$formButtonHoverBackgroundColor: $colorBase;
$formButtonFocusBackgroundColor: $colorBase;
$formButtonActionBackgroundColor: #3BD7A5;
$formButtonActionHoverBackgroundColor: #00C39D;

$formButtonCheckoutBackgroundColor: #3BD7A5;
$formButtonCheckoutHoverBackgroundColor: #00C39D;

$formTextTextareaSelectUploadBorderColor: $colorBase;
$formTextTextareaSelectUploadFocusBorderColor: $colorBase;
$formTextTextareaSelectUploadFocusBoxShadow: inset 0px 0px 0px 1px rgba($colorBase, 1);

$formInfoColor: $colorBase;


$formMessageSuccessWarningErrorBackgroundColor: #E8FAF5;
$formMessageSuccessWarningErrorColor: $colorBase;
$formSuccessBackgroundColor: #E8FAF5;
$formSuccessColor: $colorAlpha;

$formUmbracoBackgroundColor: #E8FAF5;

$formErrorColor: $colorSale;

// LAYOUT
// layout/_back-to-top.scss
$lBackToTopPrimaryBackgroundUrl: url(#{$imageRoot}layout/back-to-top/arrow.png) !default;

// layout/_breadcrumbs.scss

$cBreadcrumbsInnerContentListColor: $colorBase;

// LEGACY MODULES

// modules/_typeahead.scss

$mTypeaheadMenuBackgroundColor: $colorOffWhite;
$mTypeaheadMenuBeforeBorderColor: $colorOffWhite;
$mTypeaheadMenuSuggestionProductHoverBackgroundColor: inherit;
$mTypeaheadMenuSuggestionLinkHoverBackgroundColor: $colorWhite;
$mTypeaheadMenuFooterLinkHoverBackgroundColor: $colorBase;
$mTypeaheadMenuSuggestionTTCursorLinkBackgroundColor: $colorWhite;
$mTypeaheadMenuSuggestionTTCursorProductBackgroundColor: $colorWhite;
$mTypeaheadMenuSuggestionProductContentPriceColor: $colorBase;
$mTypeaheadMenuSuggestionProductContentPriceWasColor: $colorBase;
$mTypeaheadMenuSuggestionProductContentPriceSaleColor: $colorEmphasise;

// modules/_mobile-search.scss

$mMobileSearchProductPriceWasColor: $colorBase;
$mMobileSearchProductPriceColor: $colorBase;
$mMobileSearchProductPriceSaleColor: $colorEmphasise;

// modules/_filter-slide-out.scss

$mFilterCloseContainerBackgroundColor: $colorBase;
$mFilterTitleColor: $colorBase; 
$mFilterSubmitBackgroundColor: $colorBase;
$mFilterByColor: $colorBase;
$mFilterSlideOutControlsSortHeight: 38px;
$mFilterSlideOutControlsWrapperHeight: 58px;

// modules/_filter.scss

$mFilterTitleBeforeMobileTabletBackgroundUrl: url(#{$imageRoot}modules/filter/sprite-filter-slide-out.png);
$mFilterFormChecksRadiosInputCheckedSpanBeforeBackgroundUrl: url(#{$imageRoot}modules/filter/sprite-filter-slide-out.png);
$mFilterFormChecksImageSpanBeforeBackgroundUrl: url(#{$imageRoot}modules/filter/sprite-filter-slide-out.png);
$mFilterShowMoreAfterBackgroundUrl: url(#{$imageRoot}modules/filter/sprite-filter-slide-out.png);
$mFilterClearPrimaryBorderColor: #71797c;
$mFilterClearPrimaryColor: $colorBase;
$mFilterClearPrimaryBackgroundColor: #fff;
$mFilterClearPrimaryHoverFocusBackgroundColor: #71797C;
$mFilterTagsLinkColor: $colorBase;
$mFilterTagsLinkBeforeAfterBackgroundColor: $colorBase;
$mFilterTagsLinkHoverBackgroundColor: $colorBase;
$mFilterFormChecksRadiosSpanColor: $colorBase;

// modules/_add-to-basket-overlay.scss

$mAtbMainHeaderMessageBeforeBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbMainAddedMessageBeforeBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbMainFooterBasketButtonBeforeBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbMainFooterCloseBeforeBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbDetailItemPrevNextBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbDetailItemSpecsTitleAfterBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbQuantityAddAfterBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbQuantityWidgetButtonAfterBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbQuantityWidgetButtonHoverBackgroundColor: $formButtonHoverBackgroundColor;
$mAtbAddedMessageBeforeBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbQuantityWidgetButtonBackgroundColor: $colorLink;
$mAtbMainTitleColor: $colorBase;
$mAtbMainProductTitleLinkColor: $colorBase;
$mAtbMainProductActionViewColor: $colorLink;
$mAtbItemActionLinkColor: $colorLink;
$mAtbItemActionLinkColorHover: $colorLinkHover;

// modules/_popups.scss

$mPopupCloseBackgroundUrl: url(#{$imageRoot}misc/close.png) !default;

// modules/_basket.scss

$mBasketTableTdThDetailsComponentsColor: $colorBase;
$mBasketAddressesTitleColor: $colorBase;
$mBasketAddressesCardDtColor: $colorBase;
$mBasketAddressesMessageColor: $colorBase;
$mBasketTotalDeliveryStyledBackgroundColor: #E8FAF5;
$mBasketTotalDeliveryStyledOpenColor: $colorBase;

// modules/_calculator.scss

$mCalculatorFormResultsItemResultColor: $colorAlpha !default;

// modules/_js-tooltip.scss

$mJSTooltipTriggerBorderColor: $colorBase;
$mJSTooltipTriggerBackgroundColor: $colorBase;
$mJSTooltipTriggerHoverBeforeColor: $colorBase;

// modules/_block.scss

$mBlockQuietTitleColor: $colorBase;

// modules/category-page.scss

$mCategoryPageContentBlockHeaderColor: $colorBase;

// modules/_full-width-block.scss

$mFullWidthBlockTitleColor: $colorBase;

// modules/_need-some-help.scss

$mNeedSomeHelpColor: $colorBase;

// scripts/_jquery.gibegallery.scss

$gibe-gallery-overlay-primary-color: $colorGamma;

// NEW COMPONENTS

// components/add-product.scss
$cAddProductControlsTextFontWeight: normal;
$cAddProductControlsTextFontFamily: $resetStrongFontFamily;

// components/_alert-message.scss
$cAlertMessageInfoBackgroundColor: #E8FAF5;


// components/_button.scss

$cButtonBackgroundColor: $colorBeta;
$cButtonHoverBackgroundColor: $colorBase;
$cButtonSecondaryBorderColor: $colorBase;
$cButtonSecondaryHoverBackgroundColor: $colorBase;
$cButtonSecondaryHoverBorderColor: $colorBase;
$cButtonSecondaryCheckedBackgroundColor: $colorBase;
$cButtonSecondaryCheckedHoverBorderColor: $colorBase;
$cButtonEmphasizeBackgroundColor: #3BD7A5;
$cButtonEmphasizeHoverBackgroundColor: #00C39D;
$cButtonDarkBackgroundColor: $colorBase;
$cButtonOutlineBorderColor: $colorBase;
$cButtonOutlineHoverBackgroundColor: $colorBase;

// components/_footer.scss

$cFooterBackgroundColor: $colorBase;
$cFooterBottomBackgroundColor: #f0f0f0;
$cFooterLinkTextColor: $colorWhite;
$cFooterLinkHoverTextColor: #5095A0;
$cFooterLinkTitleTextColor: $colorWhite;
$cFooterSocialLinkTextColor: $colorWhite;
$cFooterSocialLinkHoverTextColor: #5095A0;
$cFooterInlineListLinkTextColor: $colorBase;
$cFooterCopyrightTextColor: $colorBase;
$cFooterLinkAltTextColor: $colorBase;
$cFooterContactLinkTextColor: #32312f;

// components/_header.scss

$cHeaderMiddleBackgroundColor: $colorWhite;
$cHeaderSearchTextColor: $colorBase;
$cHeaderSearchTextBackgroundColor: #f2f2f2;
$cHeaderSearchBorderColor: $colorBase;
$cHeaderSearchFocusBorderColor: $colorAlpha;
$cHeaderSearchButtonIconColor: $colorBase;
$cHeaderUtilsLinkTextColor: $colorBase;
$cHeaderUtilsDesktopCountTextColor: $colorBase;
$cHeaderSearchToggleIconColor: $colorBase;
$cHeaderSearchButtonBackgroundColor: #f2f2f2;
$cHeaderSearchWidth: 280px;
$cHeaderSearchWidthBpLarge: 330px;
$cHeaderTradeLogoMobileWidth: 130px;
$cHeaderTradeLogoTabletWidth: 150px;
$cHeaderTradeLogoDesktopWidth: 224px;

// components/_image.scss

$cImageLazyBackground: $colorWhite url(#{$imageRoot}components/image/lazy.svg) !default;

// components/_newsletter.scss

$cNewsletterIconColor: $colorBase;

// components/_newsletter-signup.scss

$cNewsletterTitleTextColor: $colorWhite;
$cNewsletterSignupIntroTextColor: $colorWhite;
$cNewsletterSignupButtonBackgroundColor: $colorAlpha;
$cNewsletterSignupButtonHoverBackgroundColor: $colorBeta;
$cNewsletterSignupInputFocusBorderColor: #5095A0;

// components/_social.scss

$cSocialLinkColor: $colorBase;
$cSocialIconColor: $colorBase;

// components/_usps.scss

$cUSPSBackgroundColor: $colorBeta;
$cUSPSDesktopLinkTextColor: $colorBase;
$cUSPSLinkTextColor: $colorWhite;
$cUSPSDesktopLinkTextColor: $colorWhite;
$cUSPSLinkHoverTextColor: $colorWhite;
$cUSPSDesktopLinkHoverTextColor: $colorWhite;
$cUSPSDesktopBackgroundColor: $colorBeta;

$cUSPSInlineBp: 1250px;
$cUSPSMaxWidth: 100%;

// components/_price.scss

$cPriceMainSaleTextColor: $colorEmphasise;
$cPriceRRPTextColor: $colorBase;

// components/_delivery.scss

$cDeliveryTextColor: $colorBase;
$cDeliveryExpeditedBackgroundUrl: url(#{$baseSitePath}/images/overrides/drench/components/delivery/expedited.svg);
$cDeliveryInBackgroundUrl: url(#{$baseSitePath}/images/overrides/drench/components/delivery/normal.svg);
$cDeliveryUnavailableBackgroundUrl: url(#{$baseSitePath}/images/overrides/drench/components/delivery/unavailable.svg);

// components/_product.scss

$cProductVariantsLinkBorderColor: #b9bebf;
$cProductVariantsLinkActiveBorderColor: #747c80;
$cProductVariantsMoreHoverBackgroundColor: $colorWhite;
$cProductTitleLinkTextColor: $colorBase;

$cProductVariantsMoreHoverTextColor: #747c80;

$cProductCategoryPlaceholderHeightSmall: 240.75px; // < 375
$cProductCategoryPlaceholderHeight390: 247.5px; // 390
$cProductCategoryPlaceholderHeight430: 265.5px; // 430
$cProductCategoryPlaceholderHeightTabletLarge: 260px; // 640
$cProductCategoryPlaceholderHeightDesktop: 446px; // 980
$cProductCategoryPlaceholderHeightLarge: 472.66px; // 1045
$cProductCategoryPlaceholderHeightMassive: 515px; // 1225
$cProductCategoryPlaceholderHeightMammoth: 624.66px; // 1650

// components/_select.scss

$cSelectBackgroundUrl: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAPCAYAAAD3T6+hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEI2REFBMzgwNDY0MTFFQUE4QUFFNDU0RkNFODhBRTciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NEI2REFBMzcwNDY0MTFFQUE4QUFFNDU0RkNFODhBRTciIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QjdBQzZBNDI3NjUzMTFFOUFDRDE4MzQ1NzUxQzMyMjkiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QjdBQzZBNDM3NjUzMTFFOUFDRDE4MzQ1NzUxQzMyMjkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6+I7utAAABP0lEQVR42rSUu2oCQRRAZ8cVtBGsEptUae0kv5AUqWKR1BKwyT+Yf7ARxN7Gyia/IJaxs7IImk6wWUFNzoW7MMi6zvoYODvLfR10dzZ4fHn9M8b8wq05YX0N+l51T/W3BduN5bKSG5iZKy1kM3WsRFiCCO5gegXZVGdH/Bslq/EirOEexheUjXXmGpk4jHXyBdhADUYXkI101gZZIY7bvbo8bOEBhmfIhjpjiyzv5mxCfQg7eD5FqjLp3SEL9/P2QF9Od2nsZpB1tUeOSy6pxqb0B7q/Q9tD1tZakQWH6uyROXHjB7RSZC2tSZX5COMjI+sTmgmypubc2rOE8lEo630HGk6uoTFZZX5ddGxY6Pk+LKECc+jBj8Z7uleQLX0GhRneePn4VuFbHpUTryJb+A6xGY/ZRKWubJJlwL8AAwC50VcMRVka/gAAAABJRU5ErkJggg==');

// components/_nav.scss

$cNavBackground: $colorBase;
$cNavMobileBackgroundImage: none;
$cNavTitleBackgroundImage: url(#{$imageRoot}components/nav/toggle.svg);
$cNavLinkHoverColor: $colorBase;
$cNavSiteWrapperCoverBackground: $colorBase;
$cNavDesktopBackgroundColor: $colorWhite;
$cNavSecondaryBackground: color.adjust($colorBase, $lightness: 5%, $space: hsl);
$cNavMegaNavTitleColor: $colorBase;
$cNavMegaNavListNoLinkColor: $colorBase;
$cNavMegaNavListTitleNoLinkColor: $colorBase;
$cNavMegaNavListLinkColor: $colorBase;
$cNavMegaNavListTitleLinkColor: $colorBase;
$cNavMegaNavListTitleColor: $colorBase;
$cNavMegaNavDimmerBackgroundColor: $colorBase;
$cNavItemSaleBackgroundColor: $colorEmphasise;
$cNavItemSaleHoverBackgroundColor: color.adjust($cNavItemSaleBackgroundColor, $lightness: -7%, $space: hsl);
$cNavItemFeatureBackgroundColor: #FAEDEE;
$cNavItemFeatureHoverBackgroundColor: color.adjust($cNavItemFeatureBackgroundColor, $lightness: -7%, $space: hsl);
$cNavItemFeatureFontColor: $colorBase;
$cNavItemFeatureBorderTopColor: color.adjust($cNavItemFeatureBackgroundColor, $lightness: -10%, $space: hsl);
$cNavItemFeatureBorderBottomColor: $cNavItemFeatureBackgroundColor;
$cNavItemAltBackgroundColor: $colorBase;
$cNavMobileRenameThisBackgroundColor: color.adjust($colorBase, $lightness: 7.5%, $space: hsl);

$cNavItemBorderColorOne: color.adjust($colorBase, $lightness: 5%, $space: hsl);
$cNavItemBorderColorTwo: color.adjust($colorBase, $lightness: -5%, $space: hsl);
$cNavItemBorderColorThree: color.adjust($colorBase, $lightness: 6%, $space: hsl);
$cNavItemBorderColorFour: $colorBase;
$cNavItemBorderColorFive: color.adjust($colorBase, $lightness: 7%, $space: hsl);
$cNavItemBorderColorSix: color.adjust($colorBase, $lightness: 5%, $space: hsl);
$cNavItemBorderColorSeven: color.adjust($colorBase, $lightness: -5%, $space: hsl);
$cNavItemBorderColorEight: color.adjust($colorEmphasise, $lightness: 6%, $space: hsl);
$cNavItemBorderColorNine: color.adjust($colorEmphasise, $lightness: -6%, $space: hsl);

// components/_rating.scss

$cRatingTextColor: $colorBase;
$cRatingInnerBackgroundUrl: url(#{$baseSitePath}/images/components/rating/star-muted.svg);

// components/_list.scss

$cListLinkToggleColor: inherit !default;

// components/_cms.scss

$cCmsH2LineHeight: 33px;
$cCmsFontSize: 17px;

// components/_overlay.scss

$cOverlayBackgroundColor: $colorAlpha;
$cOverlayTextColor: $colorWhite;
$cOverlayBestSellerBackgroundColor: $colorBase;
$cOverlaySaleBackgroundColor: $colorSale;
$cOverlayBlackFridayBackgroundColor: $colorBase;
$cOverlayPercentOffBackgroundColor: $colorEmphasise;
$cOverlayDealBackgroundColor: $colorEmphasise;
$cOverlayClearanceBackgroundColor: $colorSale;
$cOverlayWaterSavingBackgroundColor: #5095A0;
$cOverlayExpertPickBackgroundColor: #E8FAF5;
$cOverlayExpertPickTextColor: #0D7580;
$cOverlayWowFactorBackgroundColor: #0D7580;
$cOverlayLastfewBackgroundColor: $colorEmphasise;
$cOverlayEasyCleanBackgroundColor: #F2F2F0;
$cOverlayEasyCleanTextColor: $colorBase;
$cOverlayPackPriceBackgroundColor: $colorEmphasise;
$cOverlayTopBrandBackgroundColor: $colorBeta;
$cOverlayPercentOffOverStockBackgroundColor: #ffea03;
$cOverlayPercentOffOverStockColor: $colorBase;
$cOverlayFreeDeliveryBackgroundColor: #61b5a2;
$cOverlayLifetimeGuaranteeBackgroundColor: #F2F2F0;
$cOverlayLifetimeGuaranteeTextColor: $colorBase;
$cOverlaySpecialOfferBackgroundColor: #E8FAF5;
$cOverlaySpecialOfferTextColor: #0D7580;
$cOverlayWeLoveBackgroundColor: #FAEDEE;
$cOverlayWeLoveTextColor: $colorSale;
$cOverlayFlashSaleBackgroundColor: $colorSale;
$cOverlayBestBudgetBackgroundColor: #F2F2F0;
$cOverlayBestBudgetTextColor: #0D7580;
$cOverlayCyberSaleBackgroundColor: #A6E8DB;
$cOverlayCyberSaleTextColor: #475257;
$cOverlayCyberMondayBackgroundColor: #A6E8DB;
$cOverlayCyberMondayTextColor: #475257;
$cOverlayPromoCodeBackgroundColor: #0d7580;
$cOverlayPromoCodeTextColor: $colorWhite;
$cOverlayPercentOffCodeBackgroundColor: $colorSale !default;

// components/_product-page.scss

$cProductPageMainPriceColor: inherit;

// components/_section.scss

$cSectionSplashBackgroundColor: #F9EDEE;
$cSectionTitleColor: $colorBase;

// components/_cms.scss

$cCmsLinkTextDecoration: underline;

// components/_article-card.scss

$cArticleSummaryFontWeight: normal;

// components/_carousel-paging.scss

$cCarouselPagingMessageFontWeight: normal;

// components/_carousel.scss

$cCarouselAlternativePrevNextBackgroundUrl: url(#{$imageRoot}modules/top-sellers/arrows.png);
$cCarouselButtonBackgroundImage: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIHZpZXdCb3g9IjAgMCA0MCA0MCIgaGVpZ2h0PSI0MCIgd2lkdGg9IjQwIj4NCgk8Y2lyY2xlIGN4PSIyMCIgY3k9IjIwIiByPSIxOC43IiBmaWxsPSIjZmZmIiBzdHJva2U9IiM0NzUyNTciIHN0cm9rZS1taXRlcmxpbWl0PSIyLjE0MyIgc3Ryb2tlLXdpZHRoPSIyLjU3MiIvPg0KCTxwYXRoIGZpbGw9IiM0NzUyNTciIGQ9Ik0xNS43IDMyLjFjLS40IDAtLjctLjEtMS0uNC0uNS0uNS0uNS0xLjQgMC0ybDkuNy05LjctOS43LTkuN2MtLjUtLjUtLjUtMS40IDAtMiAuNS0uNSAxLjQtLjUgMiAwTDI3LjMgMTljLjUuNS41IDEuNCAwIDJMMTYuNyAzMS42Yy0uMy4zLS43LjUtMSAuNXoiLz4NCjwvc3ZnPg==");;

// components/checkout/_checkout-card.scss

$cCheckoutCardLinkColor: #0d7580;
$cCheckoutCardLinkColorHover: #0d7580;

// components/checkout/_checkout-header.scss

$cCheckoutHeaderBackgroundColor: #FFF;
$cCheckoutHeaderFontColor: #475257;

// components/checkout/_checkout-form.scss

$cCheckoutFormInputFontFamily: 'BrandonTextWeb-Regular',Helvetica,Arial,Verdana,sans-serif;
$cCheckoutFormInputColor: #475257;
$cCheckoutFormInputFocusBorderColor: #475257;
$cCheckoutFormRadioColor: #475257;
$cCheckoutFormCheckoutColor: #475257;
$cCheckoutFormAddInputColor: #475257;
$cCheckoutFormAddInputColorHover: #000;

// components/checkout/_checkout-step-indicator.scss

$cCheckoutStepIndicatorColor: #2E5E63;
$cCheckoutStepIndicatorFontSize: 22px;

// components/checkout/_checkout-overlay.scss

$cCheckoutOverlayCloseButtonColor: #475257;

// components/checkout/_checkout-tooltip.scss

$cCheckoutTooltipBackgroundColor: #475257;
$cCheckoutTooltipCloseColor: #475257;

// components/checkout/_checkout-payment-selector.scss

$cCheckoutPaymentSelectorBackgroundColor: #475257;

// components/_card.scss

$cCardTitleLinkColor: $colorAlpha;
$cCardPinnedLinkColor: $colorAlpha;

//////////////////////////////////////////
// ADDITIONAL COLOURS NOT PRESENT IN TW //
//////////////////////////////////////////
// overrides/components/_header.scss

$overrideHeaderQuickLinksLinkHoverFocusColor: $colorAlpha;
$overrideHeaderGenericLinkHoverFocusColor: $colorAlpha;
$overrideHeaderUtilsSubLinkHoverFocusColor: $colorWhite;
$overrideHeaderUtilsLinkPopulatedColor: $colorAlpha;

// overrides/components/_product.scss

$overrideProductMoreLinkBorderColor: #747c80;

// overrides/components/_select.scss

$overrideSelectBorderColor: #71797C;

// components/_battenberg.scss

$cBattenbergBackgroundColor1: #ffffff !default;
$cBattenbergBackgroundColor2: #c9dddb !default;
$cBattenbergBackgroundColor3: #475257 !default;
$cBattenbergBackgroundColor4: #0d7580 !default;

// components/_pager.scss

$cPagerSelectHeight: 38px;

// components/_load-content-placeholder.scss
$cColumnPlaceholderHeightSmall: 459px;
$cColumnPlaceholderHeightTablet: 461x;
$cColumnPlaceholderHeightDesktop: 444px;
$cColumnPlaceholderHeightLarge: 464px;
$cColumnPlaceholderHeightMassive: 474px;
$cColumnPlaceholderHeightMammoth: 480px;

$cRecommendedExtrasPlaceholderHeightSmall: 517px;
$cRecommendedExtrasPlaceholderHeightTablet: 518px;
$cRecommendedExtrasPlaceholderHeightTabletLarge: 519px;
$cRecommendedExtrasPlaceholderHeightDesktop: 516px;
$cRecommendedExtrasPlaceholderHeightLarge: 530.66px;
$cRecommendedExtrasPlaceholderHeightMassive: 508.66px;
$cRecommendedExtrasPlaceholderHeightMammoth: 557.83px;
